var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sensorCharts"},[_c('div',{staticClass:"sensorCharts-header"},[_c('div',{staticClass:"sensorCharts-header-sections"},[_c('div',{class:{
          'sensorCharts-header-sections-selected':
            _vm.selectedSection === 'Daily Water Flow',
        }},[_c('h3',{staticClass:"sensorCharts-header-sections-title",class:{
            'sensorCharts-header-sections-selected-title':
              _vm.selectedSection === 'Daily Water Flow',
          },on:{"click":function($event){_vm.selectedSection = 'Daily Water Flow'}}},[_vm._v(" Daily Water Flow ")])]),_c('div',{class:{
          'sensorCharts-header-sections-selected':
            _vm.selectedSection === 'Daily Active Hours',
        }},[_c('h3',{staticClass:"sensorCharts-header-sections-title",class:{
            'sensorCharts-header-sections-selected-title':
              _vm.selectedSection === 'Daily Active Hours',
          },on:{"click":function($event){_vm.selectedSection = 'Daily Active Hours'}}},[_vm._v(" Daily Active Hours ")])]),_c('div',{class:{
          'sensorCharts-header-sections-selected': _vm.selectedSection === 'Map',
        }},[_c('h3',{staticClass:"sensorCharts-header-sections-title",class:{
            'sensorCharts-header-sections-selected-title':
              _vm.selectedSection === 'Map',
          },on:{"click":function($event){_vm.selectedSection = 'Map'}}},[_vm._v(" Map ")])]),_c('div',{class:{
          'sensorCharts-header-sections-selected':
            _vm.selectedSection === 'Comments',
        }},[_c('h3',{staticClass:"sensorCharts-header-sections-title",class:{
            'sensorCharts-header-sections-selected-title':
              _vm.selectedSection === 'Comments',
          },on:{"click":function($event){_vm.selectedSection = 'Comments'}}},[_vm._v(" Comments ")])])])]),_c('div',{staticClass:"sensorCharts-content"},[_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="sensorCharts">
    <div class="sensorCharts-header">
      <div class="sensorCharts-header-sections">
        <div
          :class="{
            'sensorCharts-header-sections-selected':
              selectedSection === 'Daily Water Flow',
          }"
        >
          <h3
            class="sensorCharts-header-sections-title"
            :class="{
              'sensorCharts-header-sections-selected-title':
                selectedSection === 'Daily Water Flow',
            }"
            @click="selectedSection = 'Daily Water Flow'"
          >
            Daily Water Flow
          </h3>
        </div>
        <div
          :class="{
            'sensorCharts-header-sections-selected':
              selectedSection === 'Daily Active Hours',
          }"
        >
          <h3
            class="sensorCharts-header-sections-title"
            :class="{
              'sensorCharts-header-sections-selected-title':
                selectedSection === 'Daily Active Hours',
            }"
            @click="selectedSection = 'Daily Active Hours'"
          >
            Daily Active Hours
          </h3>
        </div>
        <div
          :class="{
            'sensorCharts-header-sections-selected': selectedSection === 'Map',
          }"
        >
          <h3
            class="sensorCharts-header-sections-title"
            :class="{
              'sensorCharts-header-sections-selected-title':
                selectedSection === 'Map',
            }"
            @click="selectedSection = 'Map'"
          >
            Map
          </h3>
        </div>
        <div
          :class="{
            'sensorCharts-header-sections-selected':
              selectedSection === 'Comments',
          }"
        >
          <h3
            class="sensorCharts-header-sections-title"
            :class="{
              'sensorCharts-header-sections-selected-title':
                selectedSection === 'Comments',
            }"
            @click="selectedSection = 'Comments'"
          >
            Comments
          </h3>
        </div>
      </div>
    </div>
    <div class="sensorCharts-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedSection: this.$route.name,
    };
  },
  watch: {
    selectedSection() {
      switch (this.selectedSection) {
        case "Daily Water Flow":
          this.$router.push({ name: "Daily Water Flow" });
          return;
        case "Daily Active Hours":
          this.$router.push({ name: "Daily Active Hours" });
          return;
        case "Map":
          this.$router.push({ name: "Map" });
          return;
        case "Comments":
          this.$router.push({ name: "Comments" });
          return;
        default:
          this.$router.push({ name: "Daily Water Flow" });
          return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.sensorCharts {
  flex-grow: 1;
  color: #1d2432;
  display: flex;
  flex-direction: column;
  border: 1px solid #e6eaf2;
  border-radius: 0.5em;
  @media screen and (min-width: 1024px) {
    width: 34em;
    padding-bottom: 2rem;
  }
  @media screen and (min-width: 1440px) {
    width: 48em;
  }
  @media screen and (min-width: 2560px) {
    width: 60em;
  }
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e6eaf2;
    @media screen and (max-width: 767px) {
      justify-content: center;
      align-items: center;
    }
    &-sections {
      margin-left: 1.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 60px;
      font-size: 16px;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        margin-left: 0px;
        gap: 18px;
      }
      &-title {
        &:hover {
          cursor: pointer;
        }
      }
      &-selected {
        height: 4.7em;
        border-bottom: 2px solid #003366;
        &-title {
          margin-top: 1.5em;
        }
      }
    }
  }
}
</style>

<template>
  <div class="sensorDetails">
    <div class="sensorDetails-left">
      <div class="sensorDetails-left-status">
        <div class="sensorDetails-left-status-flag">
          <p class="sensorDetails-left-status-flag-title">Flag Status</p>
          <StatusPill
            class="sensorDetails-left-status-flag-symbol"
            :status="detailWaterPoint?.status"
          ></StatusPill>
        </div>
        <div class="sensorDetails-left-status-googleMaps">
          <a
            v-if="
              detailWaterPoint?.latitude &&
              detailWaterPoint?.longitude &&
              !isNaN(detailWaterPoint.latitude) &&
              !isNaN(detailWaterPoint.longitude)
            "
            v-bind:href="
              'https://maps.google.com/?q=' +
              detailWaterPoint.latitude +
              ',' +
              detailWaterPoint.longitude
            "
            target="_blank"
          >
            <p class="sensorDetails-left-status-googleMaps-text">Google Maps</p>
          </a>
          <a v-else>
            <p class="sensorDetails-left-status-googleMaps-text disabled">
              Google Maps
            </p>
          </a>
        </div>
      </div>
      <div class="sensorDetails-left-details">
        <h3 class="sensorDetails-left-details-title">
          {{ detailWaterPoint?.waterPoint ?? "" }}
        </h3>
        <p class="sensorDetails-left-details-subTitle">
          Sensor ID {{ detailWaterPoint?.sensorId ?? "-" }}
        </p>
        <div class="sensorDetails-left-details-columns">
          <!-- Beneficiaries -->
          <div class="sensorDetails-left-details-columns-rows-card">
            <p class="sensorDetails-left-details-columns-rows-card-title">
              Est. Beneficiaries
              <InfoToolTip :position="'top'" :text="estBenefitText" />
            </p>
            <p class="sensorDetails-left-details-columns-rows-card-value">
              {{ detailWaterPoint?.beneficiaries ?? "-" }}
            </p>
          </div>
          <!-- Location Type -->
          <div class="sensorDetails-left-details-columns-rows">
            <p class="sensorDetails-left-details-columns-rows-card-title">
              Location Type
            </p>
            <LocationPill
              :locationType="detailWaterPoint?.locationType"
              :hasIcon="true"
            />
          </div>
          <!-- Change in Liters -->
          <div class="sensorDetails-left-details-columns-rows-card">
            <p class="sensorDetails-left-details-columns-rows-card-title">
              Change in Liters
              <InfoToolTip :position="'top'" :text="changeInLitersText" />
            </p>
            <div
              class="sensorDetails-left-details-columns-rows-card-percentage"
            >
              <p class="sensorDetails-left-details-columns-rows-card-value">
                {{
                  detailWaterPoint?.percentChange !== null
                    ? `${detailWaterPoint.percentChange}%`
                    : "N/A"
                }}
              </p>
              <svg
                :class="waterPointChangePercentage"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
              >
                <path
                  d="M480-156.413 156.413-480l63.891-64.891L434.5-330.696v-472.891h91v472.891l214.196-214.195L803.587-480 480-156.413Z"
                />
              </svg>
            </div>
          </div>
          <!-- 0 Liter Days -->
          <div class="sensorDetails-left-details-columns-rows">
            <p class="sensorDetails-left-details-columns-rows-card-title">
              # of 0 Liter Days
              <InfoToolTip :position="'top'" :text="num0LiterDaysText" />
            </p>
            <p class="sensorDetails-left-details-columns-rows-card-value">
              {{
                detailWaterPoint?.zeroDays || detailWaterPoint?.zeroDays === 0
                  ? detailWaterPoint.zeroDays
                  : "-"
              }}
              Days
            </p>
          </div>
          <!-- Liters per Housr -->
          <div>
            <p class="sensorDetails-left-details-columns-rows-card-title">
              Avg. Liters Per Hour
              <InfoToolTip :position="'top'" :text="avgLPerHourText" />
            </p>
            <p class="sensorDetails-left-details-columns-rows-card-value">
              {{ detailWaterPoint?.avgHourlyLiters ?? "-" }}
            </p>
          </div>

          <!-- Avg. Liters Per Day -->
          <div class="sensorDetails-left-details-columns-rows">
            <p class="sensorDetails-left-details-columns-rows-card-title">
              Avg. Liters Per Day
              <InfoToolTip :position="'top'" :text="avgLPerDayText" />
            </p>
            <p class="sensorDetails-left-details-columns-rows-card-value">
              {{ detailWaterPoint?.avgDailyLiters ?? "-" }}
            </p>
          </div>
        </div>
      </div>
      <div class="sensorDetails-left-extradetails">
        <div class="sensorDetails-left-extradetails-rows-card">
          <p class="sensorDetails-left-extradetails-rows-card-title">
            Last Recorded
          </p>
          <p class="sensorDetails-left-extradetails-rows-card-value">
            {{
              detailWaterPoint?.lastRecorded
                ? formattedDate(detailWaterPoint.lastRecorded)
                : "-"
            }}
          </p>
        </div>
        <div class="sensorDetails-left-extradetails-rows-card">
          <p class="sensorDetails-left-extradetails-rows-card-title">
            Community
          </p>
          <p class="sensorDetails-left-extradetails-rows-card-value">
            {{ detailWaterPoint?.community ?? "-" }}
          </p>
        </div>
        <div class="sensorDetails-left-extradetails-rows-card">
          <p class="sensorDetails-left-extradetails-rows-card-title">
            Service Provider
          </p>
          <p class="sensorDetails-left-extradetails-rows-card-value">
            {{ detailWaterPoint?.serviceProvider ?? "-" }}
          </p>
        </div>
        <div>
          <p class="sensorDetails-left-extradetails-rows-card-title">
            Last Red Flag
          </p>
          <p class="sensorDetails-left-extradetails-rows-card-value">
            {{
              detailWaterPoint?.redFlagLastChangedDate
                ? formattedDate(detailWaterPoint.redFlagLastChangedDate)
                : "-"
            }}
          </p>
        </div>
        <div>
          <p class="sensorDetails-left-extradetails-rows-card-title">
            Unit Admin 1
          </p>
          <p class="sensorDetails-left-extradetails-rows-card-value">
            {{ detailWaterPoint?.administrativeUnit1 ?? "-" }}
          </p>
        </div>
        <div class="sensorDetails-left-extradetails-rows-card">
          <p class="sensorDetails-left-extradetails-rows-card-title">
            Project ID
          </p>
          <p class="sensorDetails-left-extradetails-rows-card-value">
            {{ detailWaterPoint?.projectId ?? "-" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusPill from "../Shared/StatusPill.vue";
import LocationPill from "../Shared/LocationPill.vue";
import InfoToolTip from "../Shared/InfoToolTip.vue";
export default {
  components: { StatusPill, LocationPill, InfoToolTip },
  data() {
    return {
      estBenefitText:
        "Est. # of people this waterpoint may be serving based on measured liters",
      changeInLitersText:
        "Liters measured on the most recent day compared to the rolling average",
      num0LiterDaysText:
        "# of days that have less than 100 liters in the last 30 days",
      avgLPerHourText: "Avg. liters per hour based on the last 7 days",
      avgLPerDayText: "Avg. liters per day based on the last 7 days",
    };
  },
  computed: {
    detailWaterPoint() {
      return this.$store.state.detailWaterPoint;
    },
    waterPointChangePercentage() {
      return [
        {
          "percentage-arrow-positive": this.detailWaterPoint?.percentChange > 0,
          "percentage-arrow-negative": this.detailWaterPoint?.percentChange < 0,
          "percentage-arrow-na":
            this.detailWaterPoint?.percentChange === null ||
            this.detailWaterPoint?.percentChange === 0,
        },
        "percentage-arrow",
      ];
    },
  },
  methods: {
    formattedDate(date) {
      const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      const datetime = new Date(date);
      const year = datetime.getFullYear();
      const month = months[datetime.getMonth()];
      const day = datetime.getDate();
      return `${day} ${month} ${year}`;
    },
  },
};
</script>

<style scoped lang="scss">
.sensorDetails {
  &-left {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6eaf2;
    width: 20em;
    border-radius: 0.5em;
    @media only screen and (min-width: 1024px) {
      width: 26em;
    }
    @media only screen and (min-width: 1440px) {
      width: 38em;
    }
    @media only screen and (min-width: 2560px) {
      width: 50em;
    }
    &-status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #e6eaf2;
      font-size: 14px;
      &-flag {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
        align-items: center;

        margin-left: 1.5em;
        margin-top: 0px;
        &-title {
          color: #999a9d;
          font-size: 14px;
          font-weight: 600;
          margin-top: 1.25em;
          margin-bottom: 8px;
        }
        &-symbol {
          margin-bottom: 20px;
        }
      }
      &-googleMaps {
        display: flex;
        align-items: center;
        margin-right: 1.22em;
        &-text {
          color: #0c3c6d;
          text-decoration: underline;
          font-weight: 700;
        }
      }
    }
    &-details {
      margin-left: 1.22em;
      color: var(--txt-primary);
      &-title {
        font-size: 20px;
        font-weight: 600;
        margin: 1.22em 0 5px;
      }
      &-subTitle {
        font-size: var(--base-font-size);
        color: var(--txt-primary-light);
        margin-top: 0px;
      }
      &-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px 40px;
        padding-bottom: 1em;

        &-rows {
          display: flex;
          flex-direction: column;
          &-card {
            &-percentage {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;
            }
            &-title {
              color: var(--txt-primary-light);
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 0px;
            }
            &-value {
              margin-top: 5px;
              font-size: 32px;
              font-weight: 600;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    &-extradetails {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 40px;
      border-top: 1px solid #e6eaf2;
      padding-left: 1.22em;
      padding-top: var(--base-font-size);
      &-rows {
        display: flex;
        flex-direction: column;
        &-card {
          &-title {
            color: var(--txt-primary-light);
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
            margin-top: 0;
          }
          &-value {
            color: var(--txt-primary);
            margin-top: 5px;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 4px;

            padding-bottom: 0.5em;
          }
        }
      }
    }
  }
  .percentage-arrow {
    display: block;
    height: 24px;
    width: 24px;
    &-negative {
      color: var(--clr-danger-700);
    }
    &-positive {
      color: var(--clr-success-700);
      transform: rotate(180deg);
    }
    &-na {
      display: none;
    }
  }
}

.disabled {
  color: grey;
}
</style>

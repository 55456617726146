<template>
  <div class="detailPage">
    <NavBar />
    <div class="container-under-navbar">
      <div class="detailPage-header">
        <Breadcrumb />
        <HelpAction :show-add-sensor-button="false" />
      </div>
      <div class="detailPage-content">
        <SensorDetails />
        <SensorCharts />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/NavComponents/Breadcrumb.vue";
import HelpAction from "../components/NavComponents/HelpAction.vue";
import SensorDetails from "../components/Details/SensorDetails.vue";
import SensorCharts from "../components/Details/SensorCharts.vue";
import NavBar from "../components/NavComponents/NavBar.vue";

export default {
  components: {
    Breadcrumb,
    HelpAction,
    SensorDetails,
    SensorCharts,
    NavBar,
  },
  created() {
    Promise.all([this.$store.dispatch("fetchWaterPoints")]).then(() => {
      this.$store.dispatch("updateDetailWaterPoint", this.$route.params);
    });
    this.$store.dispatch("getLoggedUserData");
  },
};
</script>

<style scoped lang="scss">
.detailPage {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1.75em;
  }
  &-content {
    display: flex;
    flex-direction: row;
    gap: 1.75em;
    margin: 0em 1.75em;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
